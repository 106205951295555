// Import CSS
import '../css/index.scss';
import '../css/tailwind.scss';

// Font Awesome
import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {faAnglesDown} from '@fortawesome/free-solid-svg-icons';
[faAnglesDown].forEach((item) => {
    library.add(item);
});
window.dom = dom;
window.library = library;
window.dom.watch();

// DOM Ready
document.addEventListener("DOMContentLoaded", () => {
    // Phone
    [...document.querySelectorAll('.phone')].forEach((phone)=>{
        phone.addEventListener('click', (e)=>{
            let hPhone = e.target.innerHTML;
            let dPhone = e.target.dataset['tel'];
            let fPhone = hPhone.replace('XXX', dPhone);
            let phoneA = e.target.nextSibling;
            phoneA.innerHTML = fPhone;
            phoneA.setAttribute('href', `tel:${fPhone}`);
            phoneA.style.display = "inline";
            e.target.style.display = "none";
        });
    });

    // Scroll
    window.addEventListener('scroll', ()=>{    
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
           document.getElementById('slideDown').style.display = 'none';
        } else {        
            document.getElementById('slideDown').style.display = 'flex';
        }
    });
});