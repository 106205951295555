// Import CSS
import './css/index.scss';
import './css/tailwind.scss';

// Fontawesome
import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {faCamera} from '@fortawesome/free-solid-svg-icons';
import {faWandMagicSparkles} from '@fortawesome/free-solid-svg-icons';
import {faMicrochip} from '@fortawesome/free-solid-svg-icons';
import {faSliders} from '@fortawesome/free-solid-svg-icons';
import {faBugSlash} from '@fortawesome/free-solid-svg-icons';
import {faPlugCircleCheck} from '@fortawesome/free-solid-svg-icons';
import {faUserSecret} from '@fortawesome/free-solid-svg-icons';
import {faGithub} from '@fortawesome/free-brands-svg-icons';

// Dynamic import
// TODO: Try to make this build automatically
// Object.keys(window.components).forEach((key) => {
//     if (document.querySelector(window.components[key])) {
//         switch (key) {
//             case 'heroBanner':
//                 import(/* webpackChunkName: "heroBanner" */ 'src/components/heroBanner/Init.jsx').then((f) => f.init());
//                 break;
//             case 'navigation':
//                 import(/* webpackChunkName: "navigation" */ 'src/components/navigation/Init.jsx').then((f) => f.init());
//                 break;
//             case 'imageBoxes':
//                 import(/* webpackChunkName: "navigation" */ 'src/components/imageBoxes/Init.jsx').then((f) => f.init());
//                 break;
//             case 'perplexity':
//                 import(/* webpackChunkName: "perplexity" */ 'src/components/perplexity/render.jsx').then((f) => f.init());
//                 break;
//         }
//     }
// });

// FontAwesome
[faCamera, faWandMagicSparkles, faMicrochip, faSliders, faBugSlash, faPlugCircleCheck, faGithub, faUserSecret].forEach((item) => {
    library.add(item);
});
window.dom = dom;
window.library = library;
